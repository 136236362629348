@import "../../styles/variables.scss";

.DAS-input-box {
    --error-height: 24px;
    --input-height: 48px;
    --textarea-height: 154px;
    --label-height: 20px;
    min-height: calc(var(--input-height) + var(--error-height));
    width: 100%;
    

    &-textarea {
        min-height: calc(var(--textarea-height) + var(--error-height) + var(--label-height));
    }
}

.DAS-input {
    font-family: $font-main;
    font-style: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    gap: 24px;
    min-height: var(--input-height);
    // max-width: 315px;
    width: 100%;
    background-color: inherit;
    border-radius: 8px;

    &-border {
        border: 1px solid #E4E4E4;
        border-radius: 8px;
    }

    &-non-label {
        padding: 0px 16px;
    }

    &-search {
        gap: 8px;

        &-image {
            cursor: pointer;
        }
    }

    &-label {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: #8D8D8D;
    }

    &-error {
        color: $color-error;
    }

    &-error-box {
        display: flex;
        justify-content: flex-start;
        padding: 0px 0px 0px 16px;
        gap: 10px;
        color: $color-error;
    }

    &-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 0px;        
    }

    &-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        height: 22px;
        // max-width: 286px;
        width: 100%;
        border: none;
        outline: none;
        background-color: inherit;
        &::placeholder {
            // color
        }
    }

    &-text-textarea {
        height: calc(var(--textarea-height));
        resize: none;
    }

    &-textarea {
        min-height: calc(var(--textarea-height) + var(--label-height));
    }
}