@font-face {
    font-family: 'SF UI Display';
    src: url('../fonts/SFPRODISPLAYREGULAR.woff2') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('../fonts/SFPRODISPLAYSEMIBOLDITALIC.woff2') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('../fonts/SFPRODISPLAYBOLD.woff2') format('truetype');
    font-weight: 700;
}

