@import "../../styles/variables.scss";

.DAS-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 24px;
    max-width: 343px;
    width: 100%;
    background: $color-primary;
    border: none;
    border-radius: 12px;
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $color-secondary;
    cursor: pointer;

    &-border{
        border: 1px solid $color-secondary;
    }
    &-invert{
        background: $color-secondary;
        border: 1px solid $color-primary;
        color: $color-primary;
    }

    &-disabled{
        background: #EFEFEF;
        color: #8D8D8D;
        cursor: auto;
    }

    &-xlarge{
        height: 64px;
        font-size: 20px;
        line-height: 24px;
    }
    
    &-large{
        height: 56px;
        font-size: 16px;
        line-height: 24px;
    }

    &-middle{
        height: 48px;
        font-size: 14px;
        line-height: 22px;
    }

    &-small{
        height: 40px;
        font-size: 14px;
        line-height: 22px;
    }

}