.create-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;

    &-title{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 10px;
    }
}