@import "./variables.scss";
@import "./fonts.scss";
@import "./Calendar.scss";
@import "./switchTheme.scss";

* {
    box-sizing: border-box;
}

.work {
    background-color: var(--background-color);
}

#root {
    margin: 0 auto;
    max-width: $container-max-width;
}

button {
    background-color: inherit;
    padding: 0;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

.content {
    min-height: 90vh;
    background-color: #ffffff;
}

.center {
    margin: 0 auto;
}

.container {
    max-width: $container-max-width;
    padding: 0 15px;
}

address {
    font-style: normal;
}

.block {
    display: grid;
    justify-items: center;
    height: fit-content;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    // background-color: $color-primary;

    &-one {
        @extend .block;
        grid-template-columns: 1fr;
    }

    &-two {
        @extend .block;
        grid-template-columns: 1fr 1fr;
    }

    &-three {
        @extend .block;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &-four {
        @extend .block;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &-five {
        @extend .block;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

.category-module__item-one {
    background-color: red;
}

.grid {
    display: grid;
    justify-items: center;
    height: fit-content;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    // background-color: $color-primary;

    &-one {
        @extend .grid;
        grid-template-columns: 1fr;
    }

    &-two {
        @extend .grid;
        grid-template-columns: 1fr 1fr;
    }

    &-three {
        @extend .grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &-four {
        @extend .grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &-five {
        @extend .grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

.pos {
    &-start {
        align-items: start;
    }

    &-center {
        align-items: center;
    }

    &-end {
        align-items: end;
    }
    &-left{
		justify-items: start;
	}

	&-right{
		justify-items: end;
	}
}

@media (max-width: calc($container-max-width + 100px)) {
    .container{
        padding: 0 50px;
    }
}

@media (max-width: $breakpoint-xxl) {
    // .container{
    //     padding: 0 50px;
    // }
}

@media (max-width: $breakpoint-xl) {
    .container {
        padding: 0 40px;
        max-width: none;
    }

    .grid {
        &-five {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
}

@media (max-width: $breakpoint-lg) {
    .container {
        padding: 0 30px;
    }

    .grid {

        &-three {
            grid-template-columns: 1fr 1fr;
        }

        &-four {
            grid-template-columns: 1fr 1fr 1fr;
        }

        &-five {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media (max-width: $breakpoint-md) {
    .container {
        padding: 0 20px;
    }

    .grid {
        &-two {
            grid-template-columns: 1fr;
        }

        &-three {
            grid-template-columns: 1fr;
        }

        &-four {
            grid-template-columns: 1fr;
        }

        &-five {
            grid-template-columns: 1fr;
        }
    }
}

@media (max-width: $breakpoint-sm) {
    .container {
        padding: 0 10px;
    }
}



