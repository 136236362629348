@import "./variables.scss";

:root {
    --background-color: #ffffff;    
}

.dark {
    --background-color: gray;  
}

.green {
    --background-color: green;    
}

.blue {
    --background-color: blue;    
}