@import "../../../styles/variables.scss";

.DAS-header{
    min-height: 50px;
    display: flex;
    align-content: space-between;
    
    &_sticky {
        position: sticky;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        background-color: $color-primary;
        color: $color-secondary;
        z-index: 50;
        border-radius: 0 0 10px 10px;
    }  
}