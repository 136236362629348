@import "../../styles/variables.scss";

.file-input{

    display: flex;
    flex-direction: column;
    max-width: 343px;
    width: 100%;

    &__list{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        gap: 24px;
        width: 100%;
        height: 100%;
        background: $color-white;
        border: 1px solid #E4E4E4;
        border-radius: 8px;

        &-item{
            font-family: 'SF UI Display';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #8D8D8D;
        }
    }

    input{
        display: none;
    }
}