@import "../../styles/variables.scss";

.DAS-checkbox{
    display: flex;
    flex-direction: column;
      .custom-checkbox+label::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #adb5bd;
        border-radius: 0.25em;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
      }

    &-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding: 1px 0px;
        gap: 8px;
    }

    &-checkbox{
        position: absolute;
        opacity: 0;
        width: 20px;
        height: 20px;
        z-index: 1;

        & + label{
            display: inline-flex;
            align-items: center;
            user-select: none;

            &::before{
                content: '';
                display: inline-block;
                margin: 0 7px 0 0;
                width: 20px;
                height: 20px;
                flex-shrink: 0;
                flex-grow: 0;
                border: 1.5px solid #E4E4E4;
                border-radius: 2px;
                box-sizing: border-box;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }

        &:checked + label::before{
            border-color: $color-primary;
            background-color: $color-primary;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.55323 0.323426C8.63135 0.242808 8.76051 0.242264 8.83931 0.322221L9.86256 1.36052C9.93893 1.43802 9.93935 1.56235 9.86349 1.64035L4.06401 7.60369C3.9859 7.684 3.85707 7.6845 3.77834 7.6048L0.13866 3.91978C0.0617798 3.84194 0.0617102 3.71678 0.138504 3.63885L1.16213 2.60017C1.24038 2.52078 1.36843 2.52067 1.4468 2.59993L3.92174 5.10292L8.55323 0.323426Z' fill='white'/%3E%3C/svg%3E%0A");
        }
    }

    &-switch{
        position: absolute;
        opacity: 0;
        width: 40px;
        height: 24px;
        z-index: 1;

        & + label{
            display: inline-flex;
            align-items: center;
            user-select: none;

            &::before{
                content: '';
                display: inline-block;
                margin: 0 7px 0 0;
                width: 40px;
                height: 24px;
                padding: 2px;
                flex-shrink: 0;
                flex-grow: 0;
                border: 1.5px solid #E4E4E4;
                border-radius: 40px;
                box-sizing: border-box;
                background-color: #E4E4E4;
                background-repeat: no-repeat;
                background-position: center center;
                transition: background-color 0.75s ease;
            }

            &::after{
                content: '';
                display: inline-block;
                position: absolute;
                left: 2px;
                width: 20px;
                height: 20px;
                border: none;
                background-color: #FFFFFF;
                border-radius: 50%;
                transition: left 0.5s ease;
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM15.2127 7.30685C15.5662 6.88257 15.5089 6.25201 15.0846 5.89845C14.6604 5.54488 14.0298 5.60221 13.6762 6.02648L9.52271 11.0107C9.176 11.4268 8.98108 11.6571 8.82547 11.7986L8.81952 11.804L8.81311 11.7992C8.64532 11.6723 8.43035 11.4606 8.04739 11.0776L6.26266 9.29289C5.87214 8.90237 5.23897 8.90237 4.84845 9.29289C4.45793 9.68342 4.45793 10.3166 4.84845 10.7071L6.63317 12.4918L6.67399 12.5327C7.00049 12.8593 7.31458 13.1735 7.60683 13.3945C7.93419 13.642 8.36291 13.8748 8.91232 13.8499C9.46173 13.8249 9.86761 13.5543 10.1712 13.2782C10.4423 13.0316 10.7266 12.6903 11.0222 12.3354L11.0592 12.2911L15.2127 7.30685Z' fill='white'/%3E%3C/svg%3E");
            }
        }

        &:checked + label::before{
            transition: background-color 0.75s ease;
            border-color: $color-primary;
            background-color: $color-primary;
        }

        &:checked + label::after{
            left: 18px;
            background-color: transparent;
            transition: left 0.5s ease, background-color 0.5 ease;
        }
    }

    &-error{
        display: flex;
        justify-content: flex-start;
    }

    &-label{
        font-family: $font-main;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
    }
}